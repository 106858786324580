.landscape-alert {
  display: none;
  position: fixed;
  top: 2.8125rem;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background: $gray-blue;
  padding-top: 10%;

  .rotate-phone-icon {
    margin: auto;
    @include sprite($rotate-phone-icon);
  }

  .landscape-alert-message {
    color: $white;
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 20px;
  }
}

@media only screen and (max-device-width: 768px)  and (orientation:landscape){
  .landscape-alert {
    display: block;
  }
}