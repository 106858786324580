.nav-wrapper {
    top: 100%;
    width: 100%;
    background: $gray-dark;
    border-top: 1px solid $gray;
    z-index: 50;

  .top-bar {
      // Needed to put it on top scroll pane
      z-index: 1;

      ul.title-area {
            @media #{$small-only} {
                padding-left: 5px;
            }
        }
        h3 {
            margin: 0;
            font-weight: 700;
            a {
              color: $red !important;
                font-weight: 700;
                font-size: 1.0625rem;
                text-decoration: none;
            }
        }

        .nav-links {
            background: transparent;
            * {
                background: transparent;
            }
            a:hover {
                background: darken($gray-dark, 10%) !important;
            }
            a {
                background: $gray-dark;
                color: $white;
                text-align: center;
                text-decoration: none;
                font-size: 14px;
            }
        }

      // h3 a {
      //   color: $red !important;
      //   @media #{$small-only} {
      //     font-size: 17px;
      //   }
      // }
      // .toggle-topbar.menu-icon {
      //     a span::after {
      //         width: 24px;
      //         box-shadow: 0 0 0 1px $gray-light, 0 7px 0 1px $gray-light, 0 14px 0 1px $gray-light
      //     }
      // }
  }
}
