@import 'normalize';
@import 'theme';
@import 'sprites';
@import 'warnings';

@import 'landing/functions';
@import 'landing/flex-video';
@import 'landing/loader';

@import 'foundation/components/grid';
@import 'foundation/components/visibility';
@import 'foundation/components/top-bar';
@import 'video/nav-bar';
@import 'jquery.fullPage.scss';

/********************* Full page js css overrides ********/
#fp-nav ul,
.fp-slidesNav ul {
  li a span {
    //background-color: #fff;
    //border: #333 1px solid;
  }

}

$font-roboto: 'Roboto Slab', serif;
$font-open-sans: 'Open Sans', sans-serif;

$white: #ffffff;
$beige: #bcaaa4;
$red: #d50000;
$red-dark: #aa0a11;
$gray: #72808a;
$gray-dark: #263238;
$gray-blue: #78909c;
$black-light: #2d3b42;
$black: #000000;

$slide-transition-time: 1s;

.nav-wrapper {
    position: absolute;
    top: 100%;
    width: 100%;
    background: $gray-dark;
    border-top: 1px solid $gray;
    z-index: 50;
    .top-bar {
        background: transparent;
        ul.title-area {
            @media #{$small-only} {
                padding-left: 5px;
            }
        }
        h3 {
            margin: 0;
            color: $red;
            font-weight: 700;
            a {
                font-weight: 700;
                font-size: 1.0625rem;
                text-decoration: none;
            }
        }

        .nav-links {
            background: transparent;
            * {
                background: transparent;
            }
            a:hover {
                background: darken($gray-dark, 10%) !important;
            }
            a {
                background: $gray-dark !important;
                color: $white;
                text-align: center;
                text-decoration: none;
                font-size: 14px;
                padding: 0px;
                line-height: 3em;
            }
        }
    }
}

.icon-cta {
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: none;
  color: $gray;

  .icon {
    margin-right: 10px;
    vertical-align: middle;
  }
}

.slideshow {
  transition: background 1s linear;

  .next {
    transition: none;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transform: scale(1.03, 1.03);
  }
}

.quotes {
  position: absolute;
  top: 100%;
  color: white;
  font-size: 40px;
  font-weight: 300;
  margin: auto;
  transition: all $slide-transition-time ease-out;
  z-index: 10;

  b {
    font-family: $font-roboto;
    font-weight: 700;
  }

  span {
    display: block;
  }
}
.quotes-2 {
  position: absolute;
  top: 55%;
  left: 5%;
  color: white;
  font-size: 40px;
  font-weight: 300;
  margin: auto;
  @media #{$medium-up} {
    transition: all $slide-transition-time ease-out;
  }
  @media #{$small-only} {
    transition: none;
  }
  z-index: 10;
  @media #{$small-only} {
      font-size: 14px;
      left: 0;
      top: 47%;
  }

  .indent-1 {
    margin-left: 200px;
    @media #{$small-only} {
        margin-left: 15%;
    }
  }
  .indent-2 {
    margin-left: 300px;
    @media #{$small-only} {
        margin-left: 20%;
    }
  }
  .indent-3 {
    margin-left: 550px;
    @media #{$small-only} {
        margin-left: 25%;
    }
  }

  b {
    font-family: $font-roboto;
    font-weight: bold;
  }

  span {
    display: block;
  }
}
.footer-quotes {
  position: absolute;
  top: 96%;
  left: 40%;
  color: white;
  font-size: 12px;
  @media #{$small-only} {
      left: 0%;
      padding-left: 20px;
      top: 92%;
  }
  transition: all $slide-transition-time ease-out;
  z-index: 10;
  span {
    display: block;
  }
}

@keyframes mute-indicator {
    0% {left: -140px;}
    25% {left: -135px;}
    70% {left: -140px;}
    100% {left: -140px;}
}

.mute-button {
  position: absolute;
  top: 10%;
  @media #{$small-only} {
    right: 12%;
  }
  @media #{$medium-up} {
    right: 8%;
  }

  cursor: pointer;
  .mute-indicator {
    @media #{$small-only} {
      display: none;
    }
    opacity: 0;
    background-color: #aebdc5;
    color: $red;
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    display: block;
    left: -140px;
    top: 3px;
    padding: 4px 4px 5px 10px;
    animation-name: mute-indicator;
    animation-duration: 1.5s;
    animation-iteration-count: 5;
    animation-timing-function: ease;
    transition: opacity 1s;
    span {
      position: absolute;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 15px solid #aebdc5;
      top: 0px;
      right: -15px;
    }
  }
  .mute-icon {
    position: absolute;
    display: block;
    opacity: 1;
    transition: opacity 1s;
    @include sprite($-audio-on);
  }

  .un-mute-icon {
    position: absolute;
    display: block;
    opacity: 0;
    transition: opacity 1s;
    @include sprite($-audio-off);
  }
}
.mute-button.muted {
  .mute-icon {
    opacity: 0;
  }
  .un-mute-icon {
    opacity: 1;
  }
  .mute-indicator {
    @media #{$medium-up} {
      opacity: 1;
    }
  }
}

.audio-slide-quote {
    display: none;
    position: absolute;
    bottom: 25%;
    left: 15%;
    padding: 10px;
    font-size: 24px;
    font-weight: 300;
    background: $red;
    color: $white;
    width: 350px;
}

.audio-slide-description {
    position: absolute;
    bottom: 10%;
    left: 15%;
    @media #{$small-only} {
        left: 2%;
        font-size: 16px;
        bottom: 13%;
    }

  .audio-slide-name {
    display: inline-block;
    padding: 5px 10px;
    background: $black;
    color: $white;
    font-family: $font-roboto;
    font-weight: 700;
    @media #{$small-only} {
        opacity: 0.7;
    }
  }

  .audio-slide-affiliation {
    display: inline-block;
    padding: 5px 10px;
    font-size: 16px;
    color: $white;
    background: $gray-blue;
    @media #{$small-only} {
        opacity: 0.7;
    }
  }
}

.audio-player-wrapper {
    position: absolute;
    right: 10%;
    bottom: 10%;
    width: 350px;
    background: $red;
    z-index: 1;
    @media #{$small-only} {
        opacity: 0.4;
        width: 200px;
        bottom: 2%;
        right: 0%;
        left: 2%;
    }

  .audio-player {
    position: relative;
    color: $white;
    z-index: 10;
    padding: 10px;

    .pause {
      font-family: Arial, sans-serif;
      font-weight: 700;
      display: inline-block;
      margin-right: 5px;
      cursor: pointer;
      letter-spacing: 2px;
    }

    .description {
      display: inline-block;
    }

    .timer {
      position: absolute;
      right: 10px;
      display: inline-block;
    }
  }

  .progress-bar-wrapper {

    .progress-bar {
      position: absolute;
      top: 0;
      z-index: -1;
      background: $red-dark;
      height: 100%;
      width: 0px;
    }
  }
}

.slide-wrapper {

}

.slide-background {
  position: absolute !important;
  background-size: cover !important;
  height: 100%;
  width: 100%;
}

.slide-background-transition {
  transition: transform 6s ease-in;
}

.slide-background-zoom {
  transform: scale(1.03, 1.03);
}

.slide-1.slide-background {
  background: url('images/section-1-background.jpg') no-repeat center center;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

.slide-1 {
  .slide-top-container.pre-init {
    h1 {
      opacity: 0 !important;
    }
    h2 {
      opacity: 0 !important;
    }
    a {
      opacity: 0 !important;
    }
  }
  .slide-bottom-container.pre-init {
    opacity: 0 !important;
    bottom: -300px !important;
  }

  position: relative;
  z-index: 10;
  height: 100%;
  width: 100%;

  .audio-player-wrapper {
    display: none;
  }

  .slide-top-wrapper {
    padding: 100px 50px;
    @media #{$small-only} {
        padding: 60px 5px 15px 5px;
    }
    height: 100%;

    .slide-top-container {
      h1 {
        opacity: 1;
        display: inline-block;
        margin: 0;
        color: $white;
        background-color: $red;
        padding: 10px 20px;
        -webkit-transition: opacity 1s ease-out, top 1s ease-out;
           -moz-transition: opacity 1s ease-out, top 1s ease-out;
             -o-transition: opacity 1s ease-out, top 1s ease-out;
                transition: opacity 1s ease-out, top 1s ease-out;
        @media #{$small-only} {
            font-size: 18px;
            padding: 5px 10px;
        }
      }

      h2 {
        opacity: 1;
        display: inline-block;
        margin: 2px 0 0;
        padding: 5px 20px;
        color: $white;
        background-color: $red;
        -webkit-transition: opacity 1s ease-out, top 1s ease-out;
           -moz-transition: opacity 1s ease-out, top 1s ease-out;
             -o-transition: opacity 1s ease-out, top 1s ease-out;
                transition: opacity 1s ease-out, top 1s ease-out;
        @media #{$small-only} {
            font-size: 15px;
            padding: 5px;
        }
      }

      .cta-links {
        margin-top: 30px;
        a {
          opacity: 1;
          color: white;
          -webkit-transition: opacity 1s ease-out, top 1s ease-out;
             -moz-transition: opacity 1s ease-out, top 1s ease-out;
               -o-transition: opacity 1s ease-out, top 1s ease-out;
                  transition: opacity 1s ease-out, top 1s ease-out;
        }
      }

      .trailer-link {
        font-family: $font-roboto;
        font-weight: 700;

        .icon {
          display: inline-block;
          @include sprite($-play-trailer-icon);
        }
      }

      .videos-link {
        font-family: $font-roboto;
        font-weight: 700;

        .icon {
          display: inline-block;
          @include sprite($-videos-menu-icon);
        }
      }
    }
  }

  .slide-bottom-container {
    position: absolute;
    background-color: $gray-dark;
    bottom: 0;
    -webkit-transition: opacity 2s ease-out, bottom 2s ease-out;
       -moz-transition: opacity 2s ease-out, bottom 2s ease-out;
         -o-transition: opacity 2s ease-out, bottom 2s ease-out;
            transition: opacity 2s ease-out, bottom 2s ease-out;

    .project-description {
        @media #{$medium-up} {
            display: flex;
            padding: 25px 50px 45px;
        }
        @media #{$small-only} {
            display: inline-block;
            padding: 5px 10px;
        }

      .left-column, .right-column {
        display: inline-block;
      }

        .left-column {
            @media #{$medium-up} {
                max-width: 60%;
            }
            @media #{$small-only} {
                max-width: 100%;
            }
        }

      .right-column {
        width: 100%;
        text-align: center;
        position: relative;

        .scroll-down-container {
          display: inline-block;
          .scroll-down-icon {
            display: inline-block;
            position: absolute;
            @include sprite($scroll-tab);
            top: -25px;
            @media #{$large-up} {
              left: 250px;
            }
            @media #{$medium-only} {
              left: 100px;
            }
          }
          h3 {
            display: inline-block;
            float: left;
            color: $white;
            margin: 0;
            position: absolute;
            top: 35px;
            @media #{$large-up} {
              left: 230px;
            }
            @media #{$medium-only} {
              left: 80px;
            }
          }

        }

        .audio-message {
          .icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            @include sprite($-speaker-icon);
          }

          position: absolute;
          top: 80px;
          @media #{$large-up} {
            left: 150px;
            height: 30px;
            width: 300px;
          }
          @media #{$medium-only} {
            left: 30px;
            height: auto;
            width: 100%;
          }
          display: inline-block;
          font-family: $font-roboto;
          font-size: 12px;
          color: $gray-blue;
          background-color: $black-light;
          padding: 5px;
        }
      }

      h4 {
        color: $red;
        margin-top: 0;
        margin-bottom: 0;
      }

      p {
        color: $white;
        @media #{$xlarge-up} {
            font-size: 18px;
        }
        @media #{$large-only} {
            font-size: 16px;
        }
        @media #{$medium-only} {
            font-size: 15px;
        }
        @media #{$small-only} {
            font-size: 15px;
            text-align: justify;
        }
      }
    }
  }
}

.slide-2 {
    position: relative;
    height: 100%;
    width: 100%;
    @media #{$medium-up} {
      background: url('/images/section-2-background.jpg') no-repeat center center;
      background-size: cover;
    }
    @media #{$small-only} {
      background: url('/images/mobile/1.jpg') no-repeat center center;
      background-size: cover;
    }

  .quotes {
    top: 25%;
    width: 75%;
    left: 50%;
    margin-left: -37.5%;
    @media #{$small-only} {
        font-size: 17px;
    }

    .indent-1 {
      margin-left: 100px;
      @media #{$small-only} {
          margin-left: 15%;
      }
    }

    .indent-2 {
      margin-left: 200px;
      @media #{$small-only} {
          margin-left: 15%;
      }
    }
  }

  .leaves {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    @media #{$small-only} {
      display: none;
    }
    .leaf {
      position: absolute;
      @media #{$medium-up} {
        transition: all $slide-transition-time ease-out;
      }
      @media #{$small-only} {
        transition: none;
      }
    }

    .leaf-1 {
      bottom: -40%;
      left: 10%;
      @include sprite($-new-leaf-4);
    }

    .leaf-2 {
      top: -30%;
      left: 50%;
      @include sprite($-new-leaf-1);
    }

    .leaf-3 {
      bottom: -40%;
      left: 50%;
      @include sprite($-new-leaf-3);
    }

    .leaf-4 {
      left: 50%;
      bottom: -40%;
      @include sprite($-new-leaf-2);
    }

    .leaf-5 {
      top: -30%;
      right: 10%;
      bottom: 40%;
      @include sprite($-new-leaf-5);
    }
  }
}

.slide-3.slideshow {
  background: url('/images/section-3a-background.jpg') no-repeat center center;
}

.slide-3 {
  position: relative;
  z-index: 10;
  height: 100%;
  width: 100%;
}

.slide-4 {
  height: 100%;
  width: 100%;
  @media #{$medium-up} {
    background: url('/images/section-4-background.jpg') no-repeat center center;
    background-size: cover;
  }
  @media #{$small-only} {
    background: url('/images/mobile/2.jpg') no-repeat center center;
    background-size: cover;
  }

  .quotes {
    width: 60%;
    top: 100%;
    left: 20%;
    @media #{$small-only} {
        font-size: 18px;
        top: 25px;
        width: 100%;
        left: 5%;
    }
    .indent-1 {
      margin-left: 100px;
      @media #{$small-only} {
          margin-left: 2%;
      }
    }

    .indent-2 {
      margin-left: 125px;
      @media #{$small-only} {
          margin-left: 5%;
      }
    }
  }

  .pills {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    @media #{$small-only} {
      display: none;
    }
    .pill {
      position: absolute;
      @media #{$medium-up} {
        transition: all $slide-transition-time ease-out;
      }
      @media #{$small-only} {
        transition: none;
      }
    }

    .pill-1 {
      bottom: -20%;
      left: 5%;
      @include sprite($-pill-6);
    }

    .pill-2 {
      top: -30%;
      left: 10%;
      @include sprite($-pill-3);
    }

    .pill-3 {
      bottom: 0%;
      left: 15%;
      @include sprite($-pill-2);
    }

    .pill-4 {
      left: 40%;
      top: -40%;
      @include sprite($-pill-4);
    }

    .pill-5 {
      left: 55%;
      top: -20%;
      @include sprite($-pill-5);
    }

    .pill-6 {
      right: 10%;;
      top: -40%;
      @include sprite($-pill-1);
    }
  }
}

.slide-5.slideshow {
  background: url('/images/section-5a-background.jpg') no-repeat center center;
}

.slide-5 {
  position: relative;
  z-index: 10;
  height: 100%;
  width: 100%;
}
.mobile-quotes {
  color: white;
  font-size: 20px;
  width: 100%;
  padding: 70px 50px 0px 20px;
}
.slide-6 {
  height: 100%;
  width: 100%;
  @media #{$medium-up} {
    background: url('/images/section-6-background.jpg') no-repeat center center;
    background-size: cover;
  }
  @media #{$small-only} {
    background: url('/images/mobile/3.jpg') no-repeat center center;
    background-size: cover;
  }
  .quotes {
    width: 60%;
    left: 50%;
    margin-left: -30%;
    .indent-1 {
      margin-left: 100px;
    }

    .indent-2 {
      margin-left: 200px;
    }

    .indent-3 {
      margin-left: 300px;
    }
  }

  .coins {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    @media #{$small-only} {
      display: none;
    }
    .coin {
      position: absolute;
      @media #{$medium-up} {
        transition: all $slide-transition-time ease-out;
      }
      @media #{$small-only} {
        transition: none;
      }
    }

    .coin-1 {
      left: 10%;
      top: -40%;
      @include sprite($-loonie);
    }

    .coin-2 {
      right: 20%;
      bottom: -40%;
      @include sprite($-twoonie-large);
    }

    .coin-3 {
      right: 10%;
      top: -40%;
      @include sprite($-twoonie);
    }
  }
}

.slide-7 {
  position: relative;
  width: 100%;
  height: 100%;
  background: $beige;
  text-align: center;
  z-index: 10;

  .question {
    width: 80%;
    margin: auto;
    padding-top: 6%;
    color: $white;
    @media #{$small-only} {
        padding-top: 70px;
        h1 {
            font-size: 16px;
        }
    }
  }

  .explore-heading {
    color: $gray-dark;
    font-weight: 700;
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 30px;
    @media #{$small-only} {
        font-size: 14px;
        margin: 10px 0px;
    }
  }

    .explore-buttons {
        color: $white;
        margin-bottom: 20px;

        a {
            display: inline-block;
            text-decoration: none;
            color: white;
            font-family: $font-roboto;
            font-weight: 700;
            background: $red;
            height: 60px;
            font-size: 22px;
            width: 275px;
            @media #{$small-only} {
                width: 120px;
                font-size: 14px;
                height: 45px;
            }
            div {
                margin-top: 11px;
                @media #{$small-only} {
                    margin-top: 4px;
                }
                .text-container {
                    line-height: 1.6;
                }
                .icon {
                    display: inline-block;
                    opacity: 0.3;
                    margin-right: 5px;
                }
                span {
                    vertical-align: top;
                    @media #{$small-only} {
                        vertical-align: middle;
                        padding-top: 25px;
                    }
                }
            }
        }
        a:hover {
            background: $red-dark;
        }
        .watch-trailer {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;

            div {
                .icon {
                    @include sprite($-play-trailer-icon);
                }
            }
        }

        .videos-menu {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;

            div {
                .icon {
                    @include sprite($-videos-menu-icon);
                }
                .arrow-container {
                    display: inline-block;
                }
                .arrow {
                    display: inline-block;
                    position: relative;
                    top: 5px;
                    left: 15px;
                    @include sprite($-arrow);
                }
            }
        }
    }

    .explore-note {
        @media #{$xlarge-up} {
            max-width: 300px;
        }
        @media #{$large-only} {
            max-width: 450px;
        }
        @media #{$medium-only} {
            max-width: 400px;
        }
        @media #{$small-only} {
            max-width: none;
            padding: 0px 20px;
            font-size: 14px;
        }
        margin: auto;
        color: $white;
    }

  .footer {
    position: absolute;
    bottom: 0;
    background: $gray-dark;
    width: 100%;
    height: 100px;
    @media #{$small-only} {
        height: 70px;
    }

    .left-column {
      display: inline-block;
      width: 80%;
      @media #{$small-only} {
          width: 39%;
      }
      height: 100%;
      text-align: right;

      img {
        padding: 25px;
        float: left;
      }

      .copyright {
        display: inline-block;;
        color: $gray-blue;
        font-weight: bold;
        margin-right: 20px;
        margin-top: 40px;
        font-size: smaller;
        @media #{$small-only} {
            margin: 0px;
            padding: 10px;
            text-align: left;
        }
      }
    }

    .right-column {
      display: inline-block;
      width: 19%;
      @media #{$small-only} {
          width: 60%;
      }
      float: right;
      height: 100%;
      background: $gray-blue;
      color: $white;
      text-align: center;
      vertical-align: top;

      .social-wrapper {
        padding: 10px;

        .social-icons {
          display: block;
          margin-top: 10px;
          @media #{$small-only} {
              margin-top: 5px;
          }

          .facebook-icon {
            display: inline-block;
            @include sprite($-facebook-icon);
          }

          .twitter-icon {
            display: inline-block;
            @include sprite($-twitter-icon);
            margin-left: 10px;
          }
        }
      }
    }
  }
}


reveal-modal {
  display:none;
  position: absolute;
  top:0%;
  height: 100%;
  width: 100%;
  z-index: 100;

  .modal-body {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 50px;
    position: absolute;
    z-index: 40;
  }

  .reveal-modal-bg {
    background-color: black;
    opacity: 0.7;
    height: 100%;
    width: 100%;
    display: inline-block;
    position: absolute;
    z-index: 10;
  }

  .reveal-modal {
    width: 80%;
    height: 0;
  }

  .modal-close {
    position: absolute;
    color: white;
    z-index: 70;
    top: 3%;
    right: 4%;
    font-size: 40px;
  }
  .modal-close:hover {
    color: gray;
    cursor: pointer;
  }
  iframe {
    transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -webkit-transition: all 1s ease-out;
  }
  .ending-bg {
    display: none;
    padding-bottom: 56.34%;
    background: url('images/trailer_ending.jpg') no-repeat center center;
    background-size: cover;
  }

}

.loading-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background: $gray-blue;
  padding-top: 10%;

  h1 {
    text-align: center;
    margin-bottom: 0;
    color: $white;
  }

  p {
    color: white;
    text-align: center;
    font-size: 24px;
    margin-top: -50px;
  }

  .animated-loader {
    transform: scale(0.3);
    -webkit-transform:scale(0.3);
    margin: auto;
  }

  @media (max-device-width: 768px) {
    h1 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
    }
  }
}

reveal-modal.opened {
  display:block !important;
}

reveal-modal.trailer-finished {

  .modal-body{
    iframe {
      display: none;
    }
    .ending-bg {
      display: block;
    }

  }
}
