$font-roboto: 'Roboto Slab', serif;
$font-open-sans: 'Open Sans', sans-serif;

$white: #ffffff;
$beige: #bcaaa4;
$red: #d50000;
$red-dark: #aa0a11;
$gray: #72808a;
$gray-dark: #263238;
$gray-light-blue: #ebeff2;
$gray-blue: #78909c;
$black-light: #2d3b42;
$black: #000000;

body {
  font-family: 'Open Sans', sans-serif;

}

h1 {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  font-size: 48px;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 36px;
}

h3 {
  font-family: 'Roboto Slab', serif;
  font-weight: 300;
}

h4 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 24px;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.hide-bullets{
  list-style: none;
}
.remove-margins{
  margin: 0px;
}

.remove-padding{
  padding: 0px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid black;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid #f00;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid green;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right:10px solid blue;
}

