/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$-arrow-name: 'Arrow';
$-arrow-x: 841px;
$-arrow-y: 383px;
$-arrow-offset-x: -841px;
$-arrow-offset-y: -383px;
$-arrow-width: 15px;
$-arrow-height: 28px;
$-arrow-total-width: 858px;
$-arrow-total-height: 733px;
$-arrow-image: 'spritesheet.png';
$-arrow: (841px, 383px, -841px, -383px, 15px, 28px, 858px, 733px, 'spritesheet.png', 'Arrow', );
$-audio-off-name: 'Audio-off';
$-audio-off-x: 820px;
$-audio-off-y: 650px;
$-audio-off-offset-x: -820px;
$-audio-off-offset-y: -650px;
$-audio-off-width: 38px;
$-audio-off-height: 38px;
$-audio-off-total-width: 858px;
$-audio-off-total-height: 733px;
$-audio-off-image: 'spritesheet.png';
$-audio-off: (820px, 650px, -820px, -650px, 38px, 38px, 858px, 733px, 'spritesheet.png', 'Audio-off', );
$-audio-on-name: 'Audio-on';
$-audio-on-x: 784px;
$-audio-on-y: 610px;
$-audio-on-offset-x: -784px;
$-audio-on-offset-y: -610px;
$-audio-on-width: 38px;
$-audio-on-height: 38px;
$-audio-on-total-width: 858px;
$-audio-on-total-height: 733px;
$-audio-on-image: 'spritesheet.png';
$-audio-on: (784px, 610px, -784px, -610px, 38px, 38px, 858px, 733px, 'spritesheet.png', 'Audio-on', );
$-facebook-icon-name: 'Facebook-icon';
$-facebook-icon-x: 819px;
$-facebook-icon-y: 688px;
$-facebook-icon-offset-x: -819px;
$-facebook-icon-offset-y: -688px;
$-facebook-icon-width: 37px;
$-facebook-icon-height: 37px;
$-facebook-icon-total-width: 858px;
$-facebook-icon-total-height: 733px;
$-facebook-icon-image: 'spritesheet.png';
$-facebook-icon: (819px, 688px, -819px, -688px, 37px, 37px, 858px, 733px, 'spritesheet.png', 'Facebook-icon', );
$-loonie-name: 'Loonie';
$-loonie-x: 0px;
$-loonie-y: 594px;
$-loonie-offset-x: 0px;
$-loonie-offset-y: -594px;
$-loonie-width: 139px;
$-loonie-height: 139px;
$-loonie-total-width: 858px;
$-loonie-total-height: 733px;
$-loonie-image: 'spritesheet.png';
$-loonie: (0px, 594px, 0px, -594px, 139px, 139px, 858px, 733px, 'spritesheet.png', 'Loonie', );
$-mute-off-name: 'Mute-off';
$-mute-off-x: 744px;
$-mute-off-y: 650px;
$-mute-off-offset-x: -744px;
$-mute-off-offset-y: -650px;
$-mute-off-width: 38px;
$-mute-off-height: 38px;
$-mute-off-total-width: 858px;
$-mute-off-total-height: 733px;
$-mute-off-image: 'spritesheet.png';
$-mute-off: (744px, 650px, -744px, -650px, 38px, 38px, 858px, 733px, 'spritesheet.png', 'Mute-off', );
$-mute-on-name: 'Mute-on';
$-mute-on-x: 782px;
$-mute-on-y: 650px;
$-mute-on-offset-x: -782px;
$-mute-on-offset-y: -650px;
$-mute-on-width: 38px;
$-mute-on-height: 38px;
$-mute-on-total-width: 858px;
$-mute-on-total-height: 733px;
$-mute-on-image: 'spritesheet.png';
$-mute-on: (782px, 650px, -782px, -650px, 38px, 38px, 858px, 733px, 'spritesheet.png', 'Mute-on', );
$-new-leaf-1-name: 'New-Leaf-1';
$-new-leaf-1-x: 481px;
$-new-leaf-1-y: 0px;
$-new-leaf-1-offset-x: -481px;
$-new-leaf-1-offset-y: 0px;
$-new-leaf-1-width: 263px;
$-new-leaf-1-height: 221px;
$-new-leaf-1-total-width: 858px;
$-new-leaf-1-total-height: 733px;
$-new-leaf-1-image: 'spritesheet.png';
$-new-leaf-1: (481px, 0px, -481px, 0px, 263px, 221px, 858px, 733px, 'spritesheet.png', 'New-Leaf-1', );
$-new-leaf-2-name: 'New-Leaf-2';
$-new-leaf-2-x: 0px;
$-new-leaf-2-y: 0px;
$-new-leaf-2-offset-x: 0px;
$-new-leaf-2-offset-y: 0px;
$-new-leaf-2-width: 481px;
$-new-leaf-2-height: 184px;
$-new-leaf-2-total-width: 858px;
$-new-leaf-2-total-height: 733px;
$-new-leaf-2-image: 'spritesheet.png';
$-new-leaf-2: (0px, 0px, 0px, 0px, 481px, 184px, 858px, 733px, 'spritesheet.png', 'New-Leaf-2', );
$-new-leaf-3-name: 'New-Leaf-3';
$-new-leaf-3-x: 208px;
$-new-leaf-3-y: 451px;
$-new-leaf-3-offset-x: -208px;
$-new-leaf-3-offset-y: -451px;
$-new-leaf-3-width: 270px;
$-new-leaf-3-height: 99px;
$-new-leaf-3-total-width: 858px;
$-new-leaf-3-total-height: 733px;
$-new-leaf-3-image: 'spritesheet.png';
$-new-leaf-3: (208px, 451px, -208px, -451px, 270px, 99px, 858px, 733px, 'spritesheet.png', 'New-Leaf-3', );
$-new-leaf-4-name: 'New-Leaf-4';
$-new-leaf-4-x: 481px;
$-new-leaf-4-y: 221px;
$-new-leaf-4-offset-x: -481px;
$-new-leaf-4-offset-y: -221px;
$-new-leaf-4-width: 252px;
$-new-leaf-4-height: 223px;
$-new-leaf-4-total-width: 858px;
$-new-leaf-4-total-height: 733px;
$-new-leaf-4-image: 'spritesheet.png';
$-new-leaf-4: (481px, 221px, -481px, -221px, 252px, 223px, 858px, 733px, 'spritesheet.png', 'New-Leaf-4', );
$-new-leaf-5-name: 'New-Leaf-5';
$-new-leaf-5-x: 744px;
$-new-leaf-5-y: 160px;
$-new-leaf-5-offset-x: -744px;
$-new-leaf-5-offset-y: -160px;
$-new-leaf-5-width: 106px;
$-new-leaf-5-height: 117px;
$-new-leaf-5-total-width: 858px;
$-new-leaf-5-total-height: 733px;
$-new-leaf-5-image: 'spritesheet.png';
$-new-leaf-5: (744px, 160px, -744px, -160px, 106px, 117px, 858px, 733px, 'spritesheet.png', 'New-Leaf-5', );
$-pill-1-name: 'Pill-1';
$-pill-1-x: 0px;
$-pill-1-y: 451px;
$-pill-1-offset-x: 0px;
$-pill-1-offset-y: -451px;
$-pill-1-width: 208px;
$-pill-1-height: 143px;
$-pill-1-total-width: 858px;
$-pill-1-total-height: 733px;
$-pill-1-image: 'spritesheet.png';
$-pill-1: (0px, 451px, 0px, -451px, 208px, 143px, 858px, 733px, 'spritesheet.png', 'Pill-1', );
$-pill-2-name: 'Pill-2';
$-pill-2-x: 270px;
$-pill-2-y: 184px;
$-pill-2-offset-x: -270px;
$-pill-2-offset-y: -184px;
$-pill-2-width: 178px;
$-pill-2-height: 178px;
$-pill-2-total-width: 858px;
$-pill-2-total-height: 733px;
$-pill-2-image: 'spritesheet.png';
$-pill-2: (270px, 184px, -270px, -184px, 178px, 178px, 858px, 733px, 'spritesheet.png', 'Pill-2', );
$-pill-3-name: 'Pill-3';
$-pill-3-x: 744px;
$-pill-3-y: 0px;
$-pill-3-offset-x: -744px;
$-pill-3-offset-y: 0px;
$-pill-3-width: 114px;
$-pill-3-height: 160px;
$-pill-3-total-width: 858px;
$-pill-3-total-height: 733px;
$-pill-3-image: 'spritesheet.png';
$-pill-3: (744px, 0px, -744px, 0px, 114px, 160px, 858px, 733px, 'spritesheet.png', 'Pill-3', );
$-pill-4-name: 'Pill-4';
$-pill-4-x: 744px;
$-pill-4-y: 277px;
$-pill-4-offset-x: -744px;
$-pill-4-offset-y: -277px;
$-pill-4-width: 105px;
$-pill-4-height: 106px;
$-pill-4-total-width: 858px;
$-pill-4-total-height: 733px;
$-pill-4-image: 'spritesheet.png';
$-pill-4: (744px, 277px, -744px, -277px, 105px, 106px, 858px, 733px, 'spritesheet.png', 'Pill-4', );
$-pill-5-name: 'Pill-5';
$-pill-5-x: 786px;
$-pill-5-y: 526px;
$-pill-5-offset-x: -786px;
$-pill-5-offset-y: -526px;
$-pill-5-width: 45px;
$-pill-5-height: 45px;
$-pill-5-total-width: 858px;
$-pill-5-total-height: 733px;
$-pill-5-image: 'spritesheet.png';
$-pill-5: (786px, 526px, -786px, -526px, 45px, 45px, 858px, 733px, 'spritesheet.png', 'Pill-5', );
$-pill-6-name: 'Pill-6';
$-pill-6-x: 744px;
$-pill-6-y: 383px;
$-pill-6-offset-x: -744px;
$-pill-6-offset-y: -383px;
$-pill-6-width: 97px;
$-pill-6-height: 97px;
$-pill-6-total-width: 858px;
$-pill-6-total-height: 733px;
$-pill-6-image: 'spritesheet.png';
$-pill-6: (744px, 383px, -744px, -383px, 97px, 97px, 858px, 733px, 'spritesheet.png', 'Pill-6', );
$-play-trailer-icon-name: 'Play-Trailer-Icon';
$-play-trailer-icon-x: 786px;
$-play-trailer-icon-y: 571px;
$-play-trailer-icon-offset-x: -786px;
$-play-trailer-icon-offset-y: -571px;
$-play-trailer-icon-width: 38px;
$-play-trailer-icon-height: 38px;
$-play-trailer-icon-total-width: 858px;
$-play-trailer-icon-total-height: 733px;
$-play-trailer-icon-image: 'spritesheet.png';
$-play-trailer-icon: (786px, 571px, -786px, -571px, 38px, 38px, 858px, 733px, 'spritesheet.png', 'Play-Trailer-Icon', );
$-speaker-icon-name: 'Speaker-icon';
$-speaker-icon-x: 836px;
$-speaker-icon-y: 480px;
$-speaker-icon-offset-x: -836px;
$-speaker-icon-offset-y: -480px;
$-speaker-icon-width: 20px;
$-speaker-icon-height: 19px;
$-speaker-icon-total-width: 858px;
$-speaker-icon-total-height: 733px;
$-speaker-icon-image: 'spritesheet.png';
$-speaker-icon: (836px, 480px, -836px, -480px, 20px, 19px, 858px, 733px, 'spritesheet.png', 'Speaker-icon', );
$-twitter-icon-name: 'Twitter-icon';
$-twitter-icon-x: 782px;
$-twitter-icon-y: 688px;
$-twitter-icon-offset-x: -782px;
$-twitter-icon-offset-y: -688px;
$-twitter-icon-width: 37px;
$-twitter-icon-height: 37px;
$-twitter-icon-total-width: 858px;
$-twitter-icon-total-height: 733px;
$-twitter-icon-image: 'spritesheet.png';
$-twitter-icon: (782px, 688px, -782px, -688px, 37px, 37px, 858px, 733px, 'spritesheet.png', 'Twitter-icon', );
$-twoonie-large-name: 'Twoonie-large';
$-twoonie-large-x: 0px;
$-twoonie-large-y: 184px;
$-twoonie-large-offset-x: 0px;
$-twoonie-large-offset-y: -184px;
$-twoonie-large-width: 270px;
$-twoonie-large-height: 267px;
$-twoonie-large-total-width: 858px;
$-twoonie-large-total-height: 733px;
$-twoonie-large-image: 'spritesheet.png';
$-twoonie-large: (0px, 184px, 0px, -184px, 270px, 267px, 858px, 733px, 'spritesheet.png', 'Twoonie-large', );
$-twoonie-name: 'Twoonie';
$-twoonie-x: 139px;
$-twoonie-y: 594px;
$-twoonie-offset-x: -139px;
$-twoonie-offset-y: -594px;
$-twoonie-width: 134px;
$-twoonie-height: 134px;
$-twoonie-total-width: 858px;
$-twoonie-total-height: 733px;
$-twoonie-image: 'spritesheet.png';
$-twoonie: (139px, 594px, -139px, -594px, 134px, 134px, 858px, 733px, 'spritesheet.png', 'Twoonie', );
$-videos-menu-icon-name: 'Videos-Menu-Icon';
$-videos-menu-icon-x: 744px;
$-videos-menu-icon-y: 688px;
$-videos-menu-icon-offset-x: -744px;
$-videos-menu-icon-offset-y: -688px;
$-videos-menu-icon-width: 38px;
$-videos-menu-icon-height: 38px;
$-videos-menu-icon-total-width: 858px;
$-videos-menu-icon-total-height: 733px;
$-videos-menu-icon-image: 'spritesheet.png';
$-videos-menu-icon: (744px, 688px, -744px, -688px, 38px, 38px, 858px, 733px, 'spritesheet.png', 'Videos-Menu-Icon', );
$cc-large-name: 'cc-large';
$cc-large-x: 744px;
$cc-large-y: 610px;
$cc-large-offset-x: -744px;
$cc-large-offset-y: -610px;
$cc-large-width: 40px;
$cc-large-height: 40px;
$cc-large-total-width: 858px;
$cc-large-total-height: 733px;
$cc-large-image: 'spritesheet.png';
$cc-large: (744px, 610px, -744px, -610px, 40px, 40px, 858px, 733px, 'spritesheet.png', 'cc-large', );
$rotate-phone-icon-name: 'rotate-phone-icon';
$rotate-phone-icon-x: 273px;
$rotate-phone-icon-y: 594px;
$rotate-phone-icon-offset-x: -273px;
$rotate-phone-icon-offset-y: -594px;
$rotate-phone-icon-width: 140px;
$rotate-phone-icon-height: 109px;
$rotate-phone-icon-total-width: 858px;
$rotate-phone-icon-total-height: 733px;
$rotate-phone-icon-image: 'spritesheet.png';
$rotate-phone-icon: (273px, 594px, -273px, -594px, 140px, 109px, 858px, 733px, 'spritesheet.png', 'rotate-phone-icon', );
$scroll-tab-name: 'scroll-tab';
$scroll-tab-x: 744px;
$scroll-tab-y: 480px;
$scroll-tab-offset-x: -744px;
$scroll-tab-offset-y: -480px;
$scroll-tab-width: 92px;
$scroll-tab-height: 46px;
$scroll-tab-total-width: 858px;
$scroll-tab-total-height: 733px;
$scroll-tab-image: 'spritesheet.png';
$scroll-tab: (744px, 480px, -744px, -480px, 92px, 46px, 858px, 733px, 'spritesheet.png', 'scroll-tab', );
$team-arrow-name: 'team_arrow';
$team-arrow-x: 744px;
$team-arrow-y: 526px;
$team-arrow-offset-x: -744px;
$team-arrow-offset-y: -526px;
$team-arrow-width: 42px;
$team-arrow-height: 84px;
$team-arrow-total-width: 858px;
$team-arrow-total-height: 733px;
$team-arrow-image: 'spritesheet.png';
$team-arrow: (744px, 526px, -744px, -526px, 42px, 84px, 858px, 733px, 'spritesheet.png', 'team_arrow', );
$spritesheet-width: 858px;
$spritesheet-height: 733px;
$spritesheet-image: 'spritesheet.png';
$spritesheet-sprites: ($-arrow, $-audio-off, $-audio-on, $-facebook-icon, $-loonie, $-mute-off, $-mute-on, $-new-leaf-1, $-new-leaf-2, $-new-leaf-3, $-new-leaf-4, $-new-leaf-5, $-pill-1, $-pill-2, $-pill-3, $-pill-4, $-pill-5, $-pill-6, $-play-trailer-icon, $-speaker-icon, $-twitter-icon, $-twoonie-large, $-twoonie, $-videos-menu-icon, $cc-large, $rotate-phone-icon, $scroll-tab, $team-arrow, );
$spritesheet: (858px, 733px, 'spritesheet.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
